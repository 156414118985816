<div class="pageView" [class]="app.dashWidgetsExpanded && dashWidgetsVisible ? 'openDash' : 'closedDash'" [class.rightSection-mobile]="app.sectionView === 'right'">
  <div class="global-header">
    <global-header></global-header>
  </div>
  <div class="sidebar">
    <global-sidebar></global-sidebar>
  </div>
  <div class="dashWidgets mat-elevation-z8">
    <dashboard-banner></dashboard-banner>
  </div>
  <router-outlet></router-outlet>
  <div class="banner-control" *ngIf="dashWidgetsVisible">
    <button mat-flat-button color="primary" (click)="toggleContainer()"><fa-icon [icon]="buttonIcon"></fa-icon></button>
  </div>
  <div #phone class="phone-control">
    <div #ccpDiv id="ccpDiv"></div>
    <!-- <button mat-flat-button color="primary" (click)="togglePhone()"><fa-icon [icon]="phoneIcon"></fa-icon></button> -->
  </div>
  <div class="section-control">
    <button mat-fab color="primary" (click)="toggleSection()"><fa-icon [class.flip]="app.sectionView === 'right'" [icon]="sectionIcon"></fa-icon></button>
  </div>
</div>
<div class="legal-info">
  &copy; {{now|date:'yyyy'}} CareRight Technologies, LLC - All rights reserved
</div>