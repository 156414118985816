<mat-toolbar class="flex mat-elevation-z4">
    <div class="logoBox">
        @if(isReferrerCRM()){
            <img class="mx-auto" src="/assets/images/SRLogoText_sm.png"/>
        }@else {
            <img class="mx-auto" src="/assets/logo.png"/>
        }
    </div>
    <mat-divider [vertical]="true"></mat-divider>
    <div class="noticeBox mx-auto">
        @if(notice; as data){
            <!-- <div class="mx-auto"> -->
                @if(data.icon){
                    <mat-icon>{{data.icon}}</mat-icon>
                }
                @if(data.message){
                    {{data.message}}
                }
            <!-- </div> -->
        }
    </div>
    <mat-divider [vertical]="true"></mat-divider>
    <div class="headerButton" [matMenuTriggerFor]="helpMenu" matRipple matTooltip="Get Help">
        <fa-icon [icon]="helpIcon"></fa-icon>
        <span class="mobileHide">Get Help</span>
        <mat-menu #helpMenu="matMenu" class="navMenu">
          <mat-nav-list class="floatingMenu">
            <a mat-list-item (click)="comingSoon()" [matTooltip]="'Coming Soon'">Video Tutorial (Coming Soon)</a>
            @if(isCommunityCRM()){
              <a mat-list-item (click)="openDocumentInTab('Docentt_CRM')" [matTooltip]="'Download Manual'">Manual</a>
            } @else {
              <a mat-list-item (click)="openDocumentInTab('Docentt_RCRM')" [matTooltip]="'Download Manual'">Manual</a>
            }
            <a mat-list-item href="tel:+1{{supportNumber}}" [matTooltip]="'Call Now'">
              Call Support: {{supportNumber | mask:"(000) 000-0000" : { prefix: "" } }}
            </a>
          </mat-nav-list>
        </mat-menu>
    </div>
    <mat-divider [vertical]="true"></mat-divider>
    <div class="headerButton userBox" (click)="navigateResetState();" [matMenuTriggerFor]="usrMenu" matRipple [matTooltip]="'' + currentUser()?.nameDisplay">
      <div class="flex small-gap mx-auto">
        <person-icon [display]="currentUser()"></person-icon>
        <span class="nameCell mobileHide center-align">{{currentUser()?.nameDisplay}}</span>
      </div>
        <mat-menu #usrMenu="matMenu" class="navMenu">
          <mat-nav-list class="floatingMenu">
            <a mat-list-item *ngIf="currentUser()?.superadmin" (click)="openCompanyDialog(); navigateResetState();">Select Company</a>
            @if(isCommunityCRM()){
              <a mat-list-item (click)="openCommunityDialog(); navigateResetState();">Select Communities</a>
            }
            <a mat-list-item (click)="changePasswordDialog();">Change Password</a>
            <a mat-list-item routerLink="/settings" [class.current]="isRoute('settings')" >User Settings</a>
            <a mat-list-item (click)="logout(); navigateResetState();"><fa-icon [icon]="logoutIcon"></fa-icon> Log Out</a>
          </mat-nav-list>
        </mat-menu>
    </div>
    <!-- <div><fa-icon [icon]="logoutIcon"></fa-icon></div> -->
  </mat-toolbar>
  <!-- <mat-divider></mat-divider> -->