import { DatePipe } from "@angular/common";
import { Component, ViewChild } from "@angular/core";
import { faPowerOff, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy } from "@ngneat/until-destroy";
import { CompanyCfgService } from "../../services/company-cfg.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { AuthService } from "../../services/auth.service";
import { PersonService } from "../../services/person.service";
import { Router } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { AppService } from "../../services/app.service";
import { CompanySelectDialog } from "../../dialog/company-select/company-select.dialog";
import { CommunitySelectDialog } from "../../dialog/community-select/community-select.dialog";
import { ChangePasswordDialog } from "../../dialog/change-password-dialog.component";

@UntilDestroy()
@Component({
  selector: 'global-header',
  templateUrl: './global-header.component.html',
  styleUrls: ['./global-header.component.scss'],
  providers: [DatePipe]
})
export class GlobalHeaderComponent {
    logoutIcon = faPowerOff;
    helpIcon = faQuestionCircle;
    
    currentUser = this.personService.user;
    isCommunityCRM = this.companyCfg.communityCRM;
    isReferrerCRM = this.companyCfg.referrerCRM;
    usesPhoneApi = this.companyCfg.usesPhoneApi;
    
    supportNumber = "3077605467";
    
    notice: {icon:string, message:string}|null = null;
    // {
    //     icon: "notifications",
    //     message:"Welcome to the new Header!"
    // };
    
    // @ViewChild("usrMenuTrigger") usrMenuTrigger!: MatMenuTrigger;
    @ViewChild("helpMenuTrigger") helpMenuTrigger!: MatMenuTrigger;
    
    constructor(private router: Router, 
        private personService: PersonService, 
        private auth:AuthService, 
        private dialog:MatDialog, 
        private snackbar:MatSnackBar, 
        private api:ApiService, 
        protected datePipe: DatePipe,
        private companyCfg:CompanyCfgService,
        private app:AppService) {
    }
  
    navigateResetState(){
      this.app.sectionViewState.next("left");
    }

    // openUserMenu() {
    //   this.usrMenuTrigger.openMenu()
    // }
    // openHelpMenu() {
    //   this.helpMenuTrigger.openMenu();
    // }
  
    comingSoon(){
      this.snackbar.open("Coming Soon");
    }

    isRoute(check: string): boolean {
      return this.router.url.startsWith("/" + check);
    }

    openDocumentInTab(docName:string){
      this.api.getBlob("resource/document/"+docName+".pdf").then(data=>{
        if(data != null) {
          var file = new Blob([data], {type: 'application/pdf'});
          var fileURL = window.URL.createObjectURL(file);
          window.open(fileURL, '_blank');
        }else{
          this.snackbar.open("Something went wrong downloading documentation. Please contact Support.")
        }
      });
    }
  
    openCompanyDialog(){
      if (!this.currentUser()?.superadmin) {
        this.snackbar.open("Must be a Superadmin to change your company");
        return;
      }
      this.dialog.open(CompanySelectDialog, {disableClose: true,});
    }

    openCommunityDialog(){
      if (!this.personService.hasSomePermission(['communities:assign'])) {
        return;
      }

      this.dialog.open(CommunitySelectDialog, {disableClose: true,});
    }

    changePasswordDialog(){
      this.dialog.open(ChangePasswordDialog, {disableClose: true,});
    }
  
    logout(){
      this.auth.logoutv2();
    }
}