<h1 mat-dialog-title>{{title}}</h1>
<mat-dialog-content>
    <div class="infiniteList">
      <span class="flex-row search-row w-100">
         <search-input [(filter)]="searchData" [matTooltip]="'Search for Staff by name'" [autoFocus]="true" class="w-100"></search-input>
      </span>
      
      @if(allPeople.length > 0){
         <mat-list>
            <mat-list-item>
               <span class="selectRow">
                  <span>Staff</span>
               </span>
            </mat-list-item>
         </mat-list>
      }
      
       <mat-selection-list #selectedPerson [multiple]="false" infiniteScroll [onScroll]="personOnScroll" [filter]="searchData()">
          <load-spinner variant="table"></load-spinner>
          @for (item of allPeople; track item.id) {
             <mat-list-option [value]="item" [disabled]="emailableCheck && !item.primaryEmail">
                <span class="selectRow">
                  <div class="flex row">
                     <person-icon [display]="item" name size="small" class="auto-size"></person-icon>
                     @if(emailableCheck){
                        <span class="subText ml-auto ln125">{{item.primaryEmail ? item.primaryEmail.address : "No Email Address"}}</span>
                     }
                  </div>
                </span>
             </mat-list-option>
          }@empty {
            <mat-list-item>
               <span class="selectRow subText">
                  @if(searchData().search){
                     No staff match the given search for this community.
                  }@else {
                     This community has no known active staff to contact.
                  }
               </span>
            </mat-list-item>
          }
       </mat-selection-list>
    </div>
</mat-dialog-content>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close (click)="dialogRef.close()">Cancel</button>
  <button mat-button mat-dialog-close (click)="submit()" [disabled]="selectedPerson.selectedOptions.selected.length < 1" cdkFocusInitial>Submit</button>
</div>

