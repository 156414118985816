import { computed, effect, inject, Inject, Injectable, signal, WritableSignal } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { CompanyConfigMap } from "../models/user.models";

@Injectable()
export class CompanyCfgService{
    public cfg: WritableSignal<CompanyConfigMap|null> = signal(null);
    titleService = inject(Title);

    usesPhoneApi = computed<boolean>(()=>{
      let cfg = this.cfg()
      if(cfg && cfg.company_use_twilio){
        return cfg.company_use_twilio.value == "Y" ? true : false;
      }else{
        return false;
      }
    })
    typeOfCRM = computed<"Community CRM"|"Referrer CRM"|null>(()=>{
      let cfg = this.cfg()
      if(cfg && cfg.company_crm_type){
        return (cfg.company_crm_type.value == "Community CRM" || cfg.company_crm_type.value == "Referrer CRM") ? cfg.company_crm_type.value : null;
      }else{
        return null;
      }
    })
    communityCRM = computed(()=>{
      let cfg = this.cfg()
      if(cfg){
        return cfg.company_crm_type.value == "Community CRM";
      }else{
        return false;
      }
    })
    referrerCRM = computed(()=>{
      let cfg = this.cfg()
      if(cfg){
        return cfg.company_crm_type.value == "Referrer CRM";
      }else{
        return false;
      }
    })
    prospectScoreType = computed<"Temperature"|"Score">(()=>{
      let cfg = this.cfg();
      if(cfg){
        return cfg?.prospect_score_type.value.includes("Temperature") ? "Temperature" : "Score";
      }else{
        return "Score";
      }
    });
    titleChange = effect(()=>{
      if(this.referrerCRM()){
        this.titleService.setTitle("Senior Referrals");
      }else{
        this.titleService.setTitle("Docentt CRM");
      }
    })
}